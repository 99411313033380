export const fix = {

    // appLink: 'https://shibaripanda.serveo.net',
    // serverLink: 'http://localhost:5002',
    // serverAuthLink: 'ws://localhost:5002',
    // botName: 'irentbot'
    

    appLink: 'http://www.bot.xf10.by',
    serverLink: 'http://www.bot.xf10.by',
    serverAuthLink: 'ws://www.bot.xf10.by:5002',
    botName: 'Testxf_Bot'
}